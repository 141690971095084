.content {
  width: 400px;
  margin: auto; 
  padding: 16px;
  overflow: hidden;
}


.row {
  margin: 16px 0;
}

.buttonLogin {
  float: right;
  margin-top: 32px;
  margin-bottom: 16px!important;
  width: 100%;
}

.reset{
  margin-top: 102px;
  width: 100%;
}